import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import DropdownSearchBar from 'components/layout/dropdownSearch/DropdownSearchBar';
import { ReactNode, SyntheticEvent, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Drawer } from '../Drawer';
import styles from './AddBookDropdownMobile.module.scss';

type Props = {
  isOpen: boolean;
  closeMe: () => void;
  searchResults: ReactNode;
  bookOptions?: ReactNode;
  handleSubmit: (searchTerm: string) => void;
  mobileHeaderText: string;
};

const AddBookDropdownMobile = ({
  isOpen,
  closeMe,
  searchResults,
  bookOptions,
  handleSubmit,
  mobileHeaderText,
}: Props): JSX.Element => {
  const [searchScrollPosition, setSearchScrollPosition] = useState(0);
  const dropdownScrollElement = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!dropdownScrollElement.current) return;
    disableBodyScroll(dropdownScrollElement.current);
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  // todo: see if this scrolling can be done easier
  useEffect(() => {
    bookOptions && dropdownScrollElement.current?.scrollTo(0, 0);
    !bookOptions && dropdownScrollElement.current?.scrollTo(0, searchScrollPosition);
  }, [bookOptions]);

  const scrollEvent = (e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement;
    !bookOptions && setSearchScrollPosition(target.scrollTop);
  };

  if (isOpen) {
    return (
      <Drawer isOpen={isOpen} onClose={closeMe} headerText={mobileHeaderText}>
        <>
          {!bookOptions && (
            <div className={styles.search}>
              <DropdownSearchBar handleSubmit={handleSubmit} searchFor="books" />
            </div>
          )}
          <div className={styles.scroller} ref={dropdownScrollElement} onScroll={scrollEvent}>
            {!bookOptions && searchResults ? (
              searchResults
            ) : (
              <div className={styles.options}>{bookOptions && bookOptions}</div>
            )}
          </div>
        </>
      </Drawer>
    );
  }
  return <></>;
};

export default AddBookDropdownMobile;
