import Dropdown from 'components/basics/Dropdowns/Dropdown';
import AddBookDropdownMobile from 'components/layout/dropdownSearch/AddBookDropdownMobile';
import { BookOptionsHolder, DropdownInner } from 'components/layout/dropdownSearch/DropdownItems';
import DropdownSearchBar from 'components/layout/dropdownSearch/DropdownSearchBar';
import Portal from 'components/layout/Portal';
import { DropdownBookSearchResults } from 'containers/DropdownSearchResults';
import { BookPartsFragment } from 'generated/graphql';
import { useScreenSize } from 'hooks/useScreenSize';
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { isBook } from 'utils/typeGuards';

type Props = {
  onSelect: (book: BookPartsFragment) => void;
  renderButton: (onClick: () => void, ref: RefObject<HTMLButtonElement>) => ReactNode;
  mobileHeaderText: string;
};

const BookSelector = ({ onSelect, renderButton, mobileHeaderText }: Props): JSX.Element | null => {
  const [isOpen, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState<BookPartsFragment | Record<string, unknown>>({});
  const { smScreen } = useScreenSize();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleSubmit = (query: string) => {
    if (!query) return;
    setQuery(query);
  };

  useEffect(() => {
    if (isOpen === false) {
      setQuery('');
      setSelectedItem({});
    }
    return () => {
      setQuery('');
      setSelectedItem({});
    };
  }, [setQuery, isOpen, setSelectedItem]);

  useEffect(() => {
    if (isBook(selectedItem)) {
      onSelect(selectedItem);
      setOpen(false);
    }
  }, [selectedItem]);

  const searchResults =
    query !== '' ? <DropdownBookSearchResults query={query} selectItem={setSelectedItem} /> : null;

  if (!buttonRef) return null;

  return (
    <>
      {renderButton(() => {
        setOpen(!isOpen);
      }, buttonRef)}
      <div>
        {!smScreen ? (
          <Portal>
            <Dropdown
              positioningStrategy="fixed"
              closeMe={() => {
                setOpen(false);
              }}
              isOpen={isOpen}
              buttonRef={buttonRef}
              padding={false}
            >
              <DropdownInner>
                <DropdownSearchBar handleSubmit={handleSubmit} searchFor="books" />
                {searchResults && <BookOptionsHolder>{searchResults}</BookOptionsHolder>}
              </DropdownInner>
            </Dropdown>
          </Portal>
        ) : (
          <AddBookDropdownMobile
            isOpen={isOpen}
            closeMe={() => {
              setOpen(false);
            }}
            searchResults={searchResults}
            handleSubmit={handleSubmit}
            mobileHeaderText={mobileHeaderText}
          />
        )}
      </div>
    </>
  );
};

export default BookSelector;
