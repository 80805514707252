import { PureQueryOptions } from '@apollo/client';
import { useIsFollowingLoader } from 'dataloaders/isFollowingLoader';
import {
  AggregatedFeedDocument,
  GetFollowersDocument,
  GetFollowingDocument,
  GetProfileFollowCountsDocument,
  ProfilePartsFragment,
  useFollowProfileMutation,
  useUnfollowProfileMutation,
} from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import useMixpanel from 'hooks/useMixpanel';
import { useState } from 'react';
import { Button } from 'ui/generic';
import getApolloClient from 'utils/getApolloClient';
import { isTouchScreen } from 'utils/isTouchScreen';

type Props = {
  profile: ProfilePartsFragment;
  mini?: boolean;
  variant?: 'primary' | 'focus' | 'secondary' | 'tertiary';
  // Extra props which are passed to the tracking events
  trackingProps?: Record<string, string>;
  dontInvalidateFeedCache?: boolean;
};

export const FollowProfileButton = ({
  profile,
  mini,
  variant,
  trackingProps,
  dontInvalidateFeedCache = false,
}: Props): JSX.Element | null => {
  const { profile: loggedInProfile } = useAuthContext();
  const { trackEvent } = useMixpanel();
  const [followingText, setFollowingText] = useState('Following');

  const { data, refetch } = useIsFollowingLoader(profile.id);

  const [followProfileMutation] = useFollowProfileMutation();
  const [unfollowProfileMutation] = useUnfollowProfileMutation();

  // If user data is not loaded yet then dont show any button to prevent flicker
  if (!loggedInProfile) return null;
  if (data === undefined) return null;

  // Don't show button if attached to logged in profile
  if (loggedInProfile.id === profile.id) return null;

  function invalidateFeedCache() {
    if (dontInvalidateFeedCache) {
      return;
    } else {
      const client = getApolloClient();
      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'aggregatedFeed' });
    }
  }

  const refetchQueries: Array<PureQueryOptions> = [
    {
      query: GetProfileFollowCountsDocument,
      variables: { handle: loggedInProfile.handle },
    },
    {
      query: GetProfileFollowCountsDocument,
      variables: { handle: profile.handle },
    },
    // todo - do we still need this?
    { query: GetFollowingDocument, variables: { profileId: loggedInProfile.id } },
    {
      query: GetFollowersDocument,
      variables: { profileId: profile.id },
    },
  ];

  async function follow(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!dontInvalidateFeedCache) {
      refetchQueries.push({
        query: AggregatedFeedDocument,
        variables: { offset: 0, limit: 5 },
      });
    }
    e.preventDefault();
    e.stopPropagation();
    invalidateFeedCache();
    await followProfileMutation({
      variables: { followingProfileId: profile.id },
      refetchQueries: refetchQueries,
    });
    refetch();
    trackEvent('follow', {
      object_type: 'PROFILE',
      object_id: profile.id,
      ...(trackingProps || {}),
    });
  }

  async function unfollow() {
    if (!dontInvalidateFeedCache) {
      refetchQueries.push({
        query: AggregatedFeedDocument,
        variables: { offset: 0, limit: 5 },
      });
    }
    const areYouSure = isTouchScreen() ? confirm(`Are you sure you want to unfollow ${profile.name}?`) : true;
    if (areYouSure) {
      invalidateFeedCache();
      await unfollowProfileMutation({
        variables: { followingProfileId: profile.id },
        refetchQueries: refetchQueries,
      });
      refetch();
      trackEvent('unfollow', {
        object_type: 'PROFILE',
        object_id: profile.id,
        ...(trackingProps || {}),
      });
    }
  }

  if (!data) {
    return (
      <Button mini={mini} variant={variant} onClick={follow}>
        Follow
      </Button>
    );
  }

  return (
    <Button
      variant="focus-outline"
      mini={mini}
      onMouseEnter={() => setFollowingText('Unfollow')}
      onMouseLeave={() => setFollowingText('Following')}
      onClick={unfollow}
    >
      {followingText}
    </Button>
  );
};

export default FollowProfileButton;
