import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import { useRef, useState } from 'react';
import { ButtonSimple, LinkSimple } from 'ui/generic';
import { DownIcon } from 'ui/icons';
import { routes } from 'utils/routes';
import styles from './FooterSidebar.module.scss';

const FooterSidebar = (): JSX.Element => {
  const [activeDropdown, setActiveDropdown] = useState<
    'ShowSocial' | 'ShowHelp' | 'ShowApps' | 'ShowContribute' | null
  >(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useOutsideClick({
    refObject: menuRef,
    isActive: activeDropdown !== null,
    onOutsideClick: () => {
      setActiveDropdown(null);
    },
  });

  return (
    <div className={styles.sidebarContainer} ref={menuRef}>
      <div className={styles.top}>
        <LinkSimple variant="faded" href="/about">
          About
        </LinkSimple>
        <LinkSimple variant="faded" href="/partners">
          Partners
        </LinkSimple>
        <LinkSimple variant="faded" href="/pages/press">
          Press
        </LinkSimple>
        <LinkSimple variant="faded" href="/pages/legal">
          Legal
        </LinkSimple>
        <LinkSimple variant="faded" href="/pages/privacy-policy">
          Privacy
        </LinkSimple>
      </div>
      <div className={styles.bottom}>
        <div className={styles.col}>
          <div
            className={classNames(styles.listHeader, {
              [styles.active]: activeDropdown === 'ShowContribute',
            })}
          >
            <ButtonSimple
              variant="faded"
              onClick={() =>
                activeDropdown === 'ShowContribute'
                  ? setActiveDropdown(null)
                  : setActiveDropdown('ShowContribute')
              }
            >
              Help us
              <DownIcon />
            </ButtonSimple>
          </div>
          {activeDropdown === 'ShowContribute' && (
            <div className={styles.colActiveContent}>
              <div className={styles.link}>
                <LinkSimple variant="faded" href={routes.patrons}>
                  Become a Patron
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple variant="faded" href={routes.librarianInfo}>
                  Become a Librarian
                </LinkSimple>
              </div>
            </div>
          )}
        </div>

        <div className={styles.col}>
          <div
            className={classNames(styles.listHeader, { [styles.active]: activeDropdown === 'ShowSocial' })}
          >
            <ButtonSimple
              variant="faded"
              onClick={() =>
                activeDropdown === 'ShowSocial' ? setActiveDropdown(null) : setActiveDropdown('ShowSocial')
              }
            >
              Social
              <DownIcon />
            </ButtonSimple>
          </div>
          {activeDropdown === 'ShowSocial' && (
            <div className={styles.colActiveContent}>
              <div className={styles.link}>
                <LinkSimple
                  variant="faded"
                  href="https://twitter.com/literalclub"
                  rel="noreferrer"
                  target="_blank"
                >
                  Twitter
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple
                  variant="faded"
                  href="https://www.instagram.com/literalclub/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple
                  variant="faded"
                  href="https://www.facebook.com/literalclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple
                  variant="faded"
                  href="https://tiktok.com/@literalclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  TikTok
                </LinkSimple>
              </div>
            </div>
          )}
        </div>

        <div className={styles.col}>
          <div className={classNames(styles.listHeader, { [styles.active]: activeDropdown === 'ShowHelp' })}>
            <ButtonSimple
              variant="faded"
              onClick={() =>
                activeDropdown === 'ShowHelp' ? setActiveDropdown(null) : setActiveDropdown('ShowHelp')
              }
            >
              Support <DownIcon />
            </ButtonSimple>
          </div>
          {activeDropdown === 'ShowHelp' && (
            <div className={styles.colActiveContent}>
              <div className={styles.link}>
                <LinkSimple variant="faded" href="/pages/faq">
                  FAQ
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple variant="faded" href="/changelog">
                  Changelog
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple variant="faded" href="/feature-ideas">
                  Feature ideas
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple variant="faded" href="/pages/api">
                  API
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple variant="faded" href="/pages/widget">
                  Widget
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple variant="faded" href="/pages/contact">
                  Contact
                </LinkSimple>
              </div>
            </div>
          )}
        </div>

        {/* Apps */}
        <div className={styles.col}>
          <div className={classNames(styles.listHeader, { [styles.active]: activeDropdown === 'ShowApps' })}>
            <ButtonSimple
              variant="faded"
              onClick={() =>
                activeDropdown === 'ShowApps' ? setActiveDropdown(null) : setActiveDropdown('ShowApps')
              }
            >
              Get the app <DownIcon />
            </ButtonSimple>
          </div>
          {activeDropdown === 'ShowApps' && (
            <div className={styles.colActiveContent}>
              <div className={styles.link}>
                <LinkSimple
                  variant="faded"
                  href="https://apps.apple.com/app/literal-club/id1566119883"
                  target="_blank"
                  rel="noreferrer"
                >
                  iPhone app
                </LinkSimple>
              </div>
              <div className={styles.link}>
                <LinkSimple
                  variant="faded"
                  href="https://play.google.com/store/apps/details?id=com.literal"
                  target="_blank"
                  rel="noreferrer"
                >
                  Android app
                </LinkSimple>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterSidebar;
